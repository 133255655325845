import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Footer from './Footer';

const Main = styled.main`
  height: 95vh;
  background-image: url('/gnc/bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 50vw;
  min-width: 2000px;
  position: relative;
  z-index: -1000;
`;

const Layout = ({ children }) => (
  <>
    <Main>{children}</Main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

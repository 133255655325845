import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet/es/Helmet';

import Layout from '../../components/gnc/Layout';
import Header from '../../components/gnc/Header';
import ContestInformation from '../../components/gnc/ContestInformation';
import ContestForm from '../../components/gnc/ContestForm';

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1460px;
  min-width: 1090px;
  width: 80vw;
  margin: 0 auto;

  div {
    flex: 1;
    max-width: 630px;
  }
`;

const LandingPageExample = () => (
  <Layout>
    <Helmet>
      <meta name="viewport" content="width=1000, initial-scale=1" />
      <link rel="stylesheet" type="text/css" href="/gnc/gnc.css" />
    </Helmet>
    <Header />
    <ContentContainer>
      <ContestInformation />
      <ContestForm />
    </ContentContainer>
  </Layout>
);

export default LandingPageExample;

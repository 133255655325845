import React from 'react';
import styled from 'styled-components';
import { FaPlane, FaTicketAlt, FaDollarSign } from 'react-icons/fa';

const Header = styled.h1`
  background-color: #d81921;
  color: #fff;
  font-family: 'DIN Condensed Bold', sans-serif;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.4em;
  margin-left: -1em;
  padding: 10px 0 9px 1em;
  position: relative;
  text-shadow: 2px 2px #810f14;

  ::before {
    border-color: transparent #810f14 transparent transparent;
    border-style: solid;
    border-width: 0 20px 20px 0;
    bottom: -20px;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    width: 0;
    z-index: -10;
  }

  ::after {
    border: 65px solid #d81921;
    border-right-color: transparent;
    content: '';
    left: 95%;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  sup {
    font-size: 0.4em;
  }
`;

const HeaderRibbon = styled.div`
  background: linear-gradient(to right, #d11820, #a71118);
  content: ' ';
  height: 3.4em;
  left: -19vw;
  position: absolute;
  top: 0.4em;
  width: 20vw;
  z-index: -11;
`;

const SubHeader = styled.h2`
  font-family: 'DIN Condensed Bold', sans-serif;
  font-size: 2em;
`;

const ListItemContainer = styled.div`
  margin: 1rem;
`;

const ListItem = styled.div`
  color: #000;
  font-family: 'DIN Condensed Bold', sans-serif;
  font-size: 1.5em;
  margin-bottom: 3px;
`;

const ListItemIcon = styled.div`
  background-color: #d81921;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  line-height: 1em;
  margin-right: 1em;
  padding: 4px 6px;
`;

const ContestInformation = () => (
  <div>
    <img src="/gnc/ticketToRock.png" alt="Ticket to Rock" />
    <Header>
      Enter for a chance to win a Rock Flight
      <sup>® </sup>
      to a Live Nation
      <sup>® </sup>
      concert of your choice!
      <sup>+ </sup>
      <HeaderRibbon />
    </Header>
    <SubHeader>1 Grand Prize winner will receive:</SubHeader>
    <ListItemContainer>
      <ListItem>
        <ListItemIcon>
          <FaTicketAlt />
        </ListItemIcon>
        2 tickets to a Live Nation concert in the U.S.
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FaPlane />
        </ListItemIcon>
        Airfare & hotel accommodations for 2
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FaDollarSign />
        </ListItemIcon>
        $200 cash gift card
      </ListItem>
    </ListItemContainer>
  </div>
);

export default ContestInformation;

import React from 'react';
import styled from 'styled-components';

const LinkMock = styled.span`
  color: red;
  margin: 0 0.2rem;
  text-decoration: underline;
`;

const FooterContainer = styled.footer`
  background-color: #000;
  width: 100%;
  max-height: 10rem;
  min-width: 2000px;
  height: inherit;
`;

const FooterContent = styled.div`
  color: #8c8e90;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8em;
  margin: 0 auto;
  max-width: 1460px;
  min-width: 1090px;
  width: 80vw;
`;

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <p>
        +Live Nation produced promoted concert at an owned and operated venue in the U.S. Tickets
        are subject to program availability, venue capacity and age restrictions.
      </p>
      <p>
        NO PURCHASE NECESSARY. Legal U.S. residents 18+, who reside within a 100 mile radius of New
        York City, NY, Chicago, IL, Houston, TX, Cleveland, OH or Atlanta, GA. Void where prohibited
        by law. Begins at 12:00 PM PT on 2/9/15 and ends at 11:59 PM PT on 3/31/15. See details and
        <LinkMock>Official Rules</LinkMock>
. To enter without a GNC Game Code,
        <LinkMock>Click here</LinkMock>
        for alternative method of entry.
      </p>
      <p>
        The Sponsor of the Sweepstakes is GNC® Holdings Inc. The Administrator of the Sweepstakes is
        Live Nation Marketing, Inc., 7060 Hollywood Blvd., Los Angeles, CA 90028. The Sweepstakes
        and all accompanying materials are ©2015 Live Nation Worldwide, Inc. Live Nation and Rock
        Flight are registered trademarks of Live Nation Worldwide, Inc.
      </p>
      <p>
        <LinkMock>Click Here</LinkMock>
        for your nearest GNC location. |
        <LinkMock>Live Nation Privacy Policy</LinkMock>
|
        <LinkMock>GNC Privacy Policy</LinkMock>
      </p>
    </FooterContent>
  </FooterContainer>
);

export default Footer;

import React from 'react';
import styled from 'styled-components';

const InputRow = styled.div`
  display: flex;
  margin-bottom: 0.5em;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  label {
    color: #000;
    font-family: 'DIN Condensed Bold', sans-serif;
  }

  input {
    background-color: #f1f1f1;
    border: 1px solid #000;
    padding: 5px;
    width: 97%;
  }

  select {
    width: 97%;
  }
`;

const StyledForm = styled.form`
  background-color: #fff;
  box-shadow: 0 0 4px #aaa;
  padding: 30px 15px;
  position: relative;
`;

const MockLink = styled.span`
  color: #d71921;
  margin-left: 0.2rem;
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`;

const CheckboxRow = styled.div`
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8em;

  input {
    border: 1px solid #000;
    margin-right: 0.2rem;
  }
`;

const CaptchaContainer = styled.div`
  margin-top: 1rem;

  img {
    margin: 0;
  }
`;

const SubmitBtn = styled.button`
  background-color: #d71921;
  border: 0;
  bottom: 35px;
  color: #fff;
  font-family: 'Mocha Mattari', Arial, Helvetica, sans-serif;
  font-size: 2em;
  padding: 0.7em 1em;
  position: absolute;
  right: -20px;
  text-shadow: 2px 2px #8b080c;

  :hover {
    cursor: pointer;
  }

  ::after {
    border-color: #710d0d transparent transparent transparent;
    border-style: solid;
    border-width: 10px 20px 0 0;
    bottom: -10px;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 0;
    width: 0;
    z-index: -10;
  }
`;

const SubmitBtnShadow = styled.div`
  background-color: rgba(200, 200, 200, 0.7);
  bottom: -5px;
  content: '';
  height: 5px;
  position: absolute;
  right: 20px;
  width: 4.8em;
`;

const ContestForm = () => (
  <div>
    <StyledForm action="submit">
      <InputRow>
        <InputContainer>
          <label htmlFor="firstName">
            First Name
            <input type="text" id="firstName" />
          </label>
        </InputContainer>
        <InputContainer>
          <label htmlFor="lastName">
            Last Name
            <input type="text" id="lastName" />
          </label>
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <label htmlFor="email">
            Email
            <input type="text" id="email" />
          </label>
        </InputContainer>
        <InputContainer>
          <label htmlFor="confirmEmail">
            Confirm Email
            <input type="text" id="confirmEmail" />
          </label>
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <label htmlFor="area">
            Select Your Metro Area
            <select id="area">
              <option value={null}>Select</option>
              <option value="id">Inland Empire</option>
              <option value="la">Los Angeles</option>
              <option value="sd">San Diego</option>
            </select>
          </label>
        </InputContainer>
        <InputContainer>
          <label htmlFor="zipCode">
            ZIP Code
            <input type="text" id="zipCode" />
          </label>
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <label htmlFor="phoneNumber">
            Phone Number
            <input type="text" id="phoneNumber" />
          </label>
        </InputContainer>
        <InputContainer />
      </InputRow>
      <CheckboxRow>
        <label htmlFor="officialRules">
          <input type="checkbox" id="officialRules" />
I agree to the
          <MockLink>Official Rules</MockLink>
        </label>
      </CheckboxRow>
      <CheckboxRow>
        <label htmlFor="receiveOffers">
          <input type="checkbox" id="receiveOffers" />
          Yes, I would like to receive special offers from GNC
        </label>
      </CheckboxRow>
      <CheckboxRow>
        <label htmlFor="receiveConcertInfo">
          <input type="checkbox" id="receiveConcertInfo" />
          Yes, I would like concert information from Live Nation
        </label>
      </CheckboxRow>
      <CaptchaContainer>
        <img src="/gnc/captcha.png" alt="captcha" />
      </CaptchaContainer>
      <SubmitBtn>
        Enter Now
        <SubmitBtnShadow />
      </SubmitBtn>
    </StyledForm>
  </div>
);

export default ContestForm;

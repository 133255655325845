import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';

const HeaderContainer = styled.header`
  display: flex;
  background-color: #000;
  border-bottom: 3px solid #d71921;
  justify-content: space-between;
  margin: 0 auto 2rem;
  max-width: 1460px;
  min-width: 1090px;
  width: 80vw;
`;

const SocialContainer = styled.div`
  background-color: #d71921;
  color: #fff;
  padding: 1em;
  display: flex;
  line-height: 1em;
  text-transform: uppercase;

  div {
    margin: 0 0.4em;
  }

  span {
    margin: 0.3em;
  }
`;

const SocialBtn = styled.div`
  padding: 4px 8px;
  border: 1px solid;
  border-radius: 4px;
  vertical-align: middle;
`;

const TwitterBtn = styled(SocialBtn)`
  background-color: #1da1f2;
  border-color: #1da1f2;
`;

const FacebookBtn = styled(SocialBtn)`
  background-color: #3b5998;
  border-color: #3b5998;
`;

const LogoContainer = styled.div`
  display: flex;

  img {
    max-height: 100%;
    vertical-align: middle;
    margin: 0.9em 0.5em;
  }
`;

const Header = () => (
  <HeaderContainer>
    <LogoContainer>
      <img src="/gnc/gnc.png" alt="GNC" />
      <img src="/gnc/liveNation.png" alt="Live Nation" />
    </LogoContainer>
    <SocialContainer>
      <span>share</span>
      <FacebookBtn>
        <FaFacebookF />
      </FacebookBtn>
      <TwitterBtn>
        <FaTwitter />
      </TwitterBtn>
    </SocialContainer>
  </HeaderContainer>
);

export default Header;
